import restaurant from "./images/capitol.jpg";
import biserica from "./images/biserica.png";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/fon5.avif";
import imgheader from "./images/fon5.avif";
import imgheadermiini from "./images/panda4.jpg";
import imgheadermiinimb from "./images/panda3.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Patrick",
        familia: "fam. Ungureanu",
        logo: logo,
        tata: "Mihail",
        mama: "Elena",
        data: "29 Aprilie 2023",
        data_confirmare: "5 aprilie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "anusic007@gmail.com", 
       tel: "+40758771777",
       phone: "tel:+40758771777",
       viber: "viber://chat?number=%2B40758771777",
       whatsapp: "https://wa.me/+40758771777",
       messenger: "https://www.messenger.com/t/",
       tel1: "+40751786051",
       phone1: "tel:+40751786051",
       viber1: "viber://chat?number=%2B40751786051",
       whatsapp1: "https://wa.me/+4075178605188",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sfântul Nectarie",
            data: "29 aprilie 2023, sâmbătă, ora 13:30",
            adress: "Bulevardul Alexandru cel Bun, Iași, România",
            harta: "https://goo.gl/maps/9im8Xwq2PDweSMfaA",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Capitol",
            data: "29 aprilie 2023, sâmbătă, ora 19:00",
            adress: "Nr. 203 A, Șos. Nicolina, Iași 707317",
            harta: "https://goo.gl/maps/gEm6samhXDs8MCqW6",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar prețios de la Dumnezeu, iar botezul este grija cu care Dumnezeu ni-l oferă pe viață!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;